















































import { Component, Vue } from "vue-property-decorator";

@Component
export default class HowMuchPizzaEach extends Vue {
  pizzaCount = 2;
  pizzaSize = 12;

  get peopleCount(): number {
    return this.$store.state.peopleCount;
  }
  set peopleCount(value: number) {
    this.$store.commit('updatePeopleCount', value);
  }

  get pizzaResult(): number {
    return Math.sqrt(
      (this.pizzaCount * this.pizzaSize ** 2) / this.peopleCount
    );
  }
}
